<template>
  <div class="popup_wrap" style="width:800px">
    <!-- popup_wrap -->
    <button class="layer_close" @click="[$emit('close'), clearData()]">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.MYIN030T010.025') }}</h1>
      <!-- content_box -->
      <div class="content_box">
        <form id="frm">
          <div class="mile_desc">{{ $t('msg.MYIN030T010.026') }} <span>{{ totalMamt | comma }}</span> {{ $t('msg.MYIN030T010.027') }}</div>
          <div>{{ $t('msg.MYIN030T010.028') }}</div>
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:25%">
              <col style="width:25%">
              <col style="width:auto">
            </colgroup>
            <tbody>
              <tr>
                <th colspan="2" scope="row">{{ $t('msg.MYIN030T010.029') }}</th>
                <td>
                  <input id="reqMileage" type="text" ref="reqMile"
                         v-model="param.reqMileage"
                         @change="checkReqMile($event)"
                         @blur="checkValidationReqMile()"
                  >
                </td>
              </tr>
              <tr>
                <th colspan="2" scope="row">{{ $t('msg.MYIN030T010.030') }}</th>
                <td><input id="remMileage" type="text" :readonly="true" v-model="remMileage"></td>
              </tr>
              <tr>
                <th rowspan="3" scope="row">{{ $t('msg.MYIN030T010.031') }}<br>{{ $t('msg.MYIN030T010.032') }}</th>
                <th scope="row">
                  {{ $t('msg.MYIN050T010.003') }}
                </th>
                <td>
                  <input id="reqName" type="text"
                         v-model="param.reqName"
                         @blur="checkValidationReqName()"
                  >
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {{ $t('msg.MYIN030T010.033') }}
                </th>
                <td>
                  <input id="reqPhone" type="text" ref="phone"
                         v-model="param.reqPhone"
                         @blur="checkValidationReqPhone()"
                         @change="checkReqPhone($event)"
                  >
                </td>
              </tr>
              <tr>
                <th scope="row">
                  email
                </th>
                <td>
                  <input id="reqEmail" type="text" ref="email"
                         v-model="param.reqEmail"
                         @blur="checkValidationReqEmail()"
                         @change="checkReqEmail($event)"
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt10">
            {{ $t('msg.CSBL260.009') }}
            <!-- 마일리지 전환 상품은 영업일 기준 2일내 수신정보상의 휴대폰 번호로 발송됩니다. [공휴일 및 국경일 제외] -->
          </div>
          <ul class="bul_list">
            <li>
              {{ $t('msg.MYIN030T010.034') }}
              <!-- 귀하께서 요청하신 마일리지 전환 금액은 48시간 내 귀하의 핸드폰으로 송금해드릴 예정입니다. -->
              <ul>
                <li>{{ $t('msg.MYIN030T010.035') }}</li>
              </ul>
            </li>
            <li>{{ $t('msg.MYIN030T010.036') }}</li>
            <li>{{ $t('msg.MYIN030T010.037') }}</li>
          </ul>
          <div class="txt mt10">{{ $t('msg.MYIN030T010.038') }}</div>
          <div class="normal_box">
            <ol>
              <li>{{ $t('msg.MYIN030T010.039') }}</li>
              <li>{{ $t('msg.MYIN030T010.040') }}</li>
              <li>{{ $t('msg.MYIN030T010.041') }}</li>
              <li>{{ $t('msg.MYIN030T010.042') }}</li>
              <li>{{ $t('msg.MYIN030T010.043') }}</li>
            </ol>
            <div class="mt5">{{ $t('msg.MYIN030T010.044') }}</div>
            <div class="mt15">{{ $t('msg.MYIN030T010.045') }}</div>
          </div>
        </form>
      </div>
      <div>
        <input type="checkbox" id="chkAgree" v-model="checkAgreement"
               @change="checkReqAgreement()"
        >
        <label for="chkAgree">{{ $t('msg.MYIN030T010.045') }}</label>
      </div>
      <!-- content_box // -->

      <!-- content_box -->
      <div class="text_center mt10">
        <a href="#none" class="button blue lg ml_auto" @click="reqMileageToPoint">{{ $t('msg.MYIN030T010.007') }}</a>
        <a href="#none" class="button gray lg" @click="[$emit('close'), clearData()]">{{ $t('msg.MYIN030T010.022') }}</a>
      </div>
      <!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import mileage from '@/api/rest/user/mileage'

export default {
  name: 'MileagePoint',
  filters: {
    comma (val) {
      var num = Number(val)
      return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  props: ['totmileamt'],
  data: function () {
   return {
     remMileage: this.totmileamt,
     checkAgreement: false,

     param: {
        reqMileage: 0,
        reqEmail: '',
        reqPhone: '',
        reqName: ''
     }
   }
  },
  computed: {
     totalMamt: {
       get () {
          return this.totmileamt ? this.totmileamt : ''
       },
       set () {

       }
    }
  },
  methods: {
    clearData () {
      this.remMileage = this.totmileamt
      this.param.reqMileage = ''
      this.param.reqEmail = ''
      this.param.reqPhone = ''
      this.param.reqName = ''
      this.checkAgreement = false
    },
    confirmFun () {
         mileage.refundMileage(this.param)
            .then(response => {
              if (response.status === 200) {
                this.openAlert(this.$t('msg.CSBL260.017')) // 마일리지 전환신청
                this.$emit('close')
              } else {
                console.log(response.status)
              }

              // 데이터 초기화
              this.param.reqMileage = ''
              this.param.reqEmail = ''
              this.param.reqPhone = ''
              this.param.reqName = ''
              this.remMileage = this.totmileamt
              this.checkAgreement = false
          }).catch(err => {
              this.openAlert(this.$t('msg.CSBL260.018'))
              console.log(err)
          })
    },
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg) {
          let obj = {
            onClose: this.closeFun,
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$t('msg.MYIN030T010.007'),
            useIcon: false,
            customClass: 'simple_alert',
            message: msg,
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
    },
    openConfirmAlert (msg, func) {
      let obj = {
        onClose: this.closeFun,
        onConfirm: this.confirmFun,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.MYIN030T010.022'),
        customConfirmBtnText: this.$t('msg.MYIN030T010.007'),
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    checkReqMile (event) {
      var amt = Number(event.target.value)
      var sum = Number(this.remMileage)

      if (amt % 10000 !== 0) {
        this.openAlert(this.$t('msg.MYIN030T010.047'))
        this.param.reqMileage = ''
        this.remMileage = this.totmileamt
        this.$refs.reqMile.focus()
      } else if (amt > sum) {
        this.openAlert(this.$t('msg.MYIN030T010.048'))
        this.param.reqMileage = ''
        this.remMileage = this.totmileamt
        this.$refs.reqMile.focus()
      } else if (amt < 0) {
        this.openAlert(this.$t('msg.MYIN030T010.049'))
        this.param.reqMileage = ''
        this.remMileage = this.totmileamt
        this.$refs.reqMile.focus()
      } else {
        this.remMileage = Number(sum - amt)
        this.param.reqMileage = Number(event.target.value)
      }
    },
    checkReqEmail (event) {
      const val = event.target.value
      const elem = frm.querySelector('#inputEmail')

      let isOk = true
      let msg = ''

      if (val === '') {
        // 값이 없을 경우
        msg = this.$t('msg.MAIN100.202') + this.$t('tem.CMAT002')
        isOk = false
      } else if (!this.$ekmtcCommon.checkEmail(val)) {
        // 이메일 형식 체크
        msg = this.$t('art.CMATK196') // 잘못된 형식의 이메일 주소가 있습니다.'
        isOk = false
      } else {
        // 값이 있을 경우, Byte 크기 체크
        if (!this.$ekmtcCommon.checkTextByte(val, 2000)) {
          msg = this.$t('msg.ADMN030G020.023') // 허용된 Byte 크기를 초과하였습니다.'
          isOk = false
        }
      }

      if (!isOk) {
        this.openAlert(msg)
        event.target.value = ''
        this.$refs.email.focus()
      }
    },
    checkReqPhone (event) {
      if (!this.phoneCheck(event.target.value)) {
        this.openAlert(this.$t('msg.CSBL260.016'))
        event.target.value = ''
        this.param.reqPhone = ''
        this.$refs.phone.focus()
      }
    },
    phoneCheck (obj) {
        var v_normal = /[^0-9]/g
        if (v_normal.test(obj) || isNaN(parseInt(obj))) {
          return false
        }
        return true
    },
    checkReqAgreement () {
        var chkAgree = this.checkAgreement

        if (!chkAgree) {
          this.openAlert(this.$t('msg.MYIN030T010.046'))
          return false
        }
        return true
    },
    checkValidationReqMile (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#reqMileage')
      const val = this.param.reqMileage
      let isOk = true
      let msg = ''

      if (val === '' || val === 0) {
        msg = this.$t('msg.CSBL260.020')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.remMileage = this.totmileamt
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationReqName (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#reqName')
      const val = this.param.reqName
      let isOk = true
      let msg = ''

      if (val === '' || val === 0) {
        msg = this.$t('msg.CSBL260.021')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationReqPhone (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#reqPhone')
      const val = this.param.reqPhone
      let isOk = true
      let msg = ''

      if (val === '' || val === 0) {
        msg = this.$t('msg.CSBL260.022')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationReqEmail (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }
      const elem = frm.querySelector('#reqEmail')
      const val = this.param.reqEmail
      let isOk = true
      let msg = ''

      if (val === '' || val === 0) {
        msg = this.$t('msg.CSBL260.023')
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    checkValidationAll () {
      const frm = document.querySelector('#frm')
      let isAllOk = true

      if (!this.checkValidationReqMile(frm)) {
        isAllOk = false
      }
      if (!this.checkValidationReqName(frm)) {
        isAllOk = false
      }
      if (!this.checkValidationReqPhone(frm)) {
        isAllOk = false
      }
      if (!this.checkValidationReqEmail(frm)) {
        isAllOk = false
      }
      if (!this.checkReqAgreement()) {
        isAllOk = false
      }

        return isAllOk
    },
    reqMileageToPoint () {
      const isOk = this.checkValidationAll()

      if (isOk) {
        this.openConfirmAlert(this.$t('msg.CSBL260.019'))
        console.log('request-Data')
      }
    }
  }
}
</script>

<style scoped>
.simplert {
  z-index: 9000 !important;
}

</style>
