var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              ;[_vm.$emit("close"), _vm.clearData()]
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.025"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("form", { attrs: { id: "frm" } }, [
            _c("div", { staticClass: "mile_desc" }, [
              _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.026")) + " "),
              _c("span", [_vm._v(_vm._s(_vm._f("comma")(_vm.totalMamt)))]),
              _vm._v(" " + _vm._s(_vm.$t("msg.MYIN030T010.027"))),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.028")))]),
            _c("table", { staticClass: "tbl_row mt10" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.029"))),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.reqMileage,
                          expression: "param.reqMileage",
                        },
                      ],
                      ref: "reqMile",
                      attrs: { id: "reqMileage", type: "text" },
                      domProps: { value: _vm.param.reqMileage },
                      on: {
                        change: function ($event) {
                          return _vm.checkReqMile($event)
                        },
                        blur: function ($event) {
                          return _vm.checkValidationReqMile()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.param, "reqMileage", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", { attrs: { colspan: "2", scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.030"))),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.remMileage,
                          expression: "remMileage",
                        },
                      ],
                      attrs: { id: "remMileage", type: "text", readonly: true },
                      domProps: { value: _vm.remMileage },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.remMileage = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", { attrs: { rowspan: "3", scope: "row" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.031"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.032"))),
                  ]),
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T010.003")) + " "),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.reqName,
                          expression: "param.reqName",
                        },
                      ],
                      attrs: { id: "reqName", type: "text" },
                      domProps: { value: _vm.param.reqName },
                      on: {
                        blur: function ($event) {
                          return _vm.checkValidationReqName()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.param, "reqName", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("msg.MYIN030T010.033")) + " "),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.reqPhone,
                          expression: "param.reqPhone",
                        },
                      ],
                      ref: "phone",
                      attrs: { id: "reqPhone", type: "text" },
                      domProps: { value: _vm.param.reqPhone },
                      on: {
                        blur: function ($event) {
                          return _vm.checkValidationReqPhone()
                        },
                        change: function ($event) {
                          return _vm.checkReqPhone($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.param, "reqPhone", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [_vm._v(" email ")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.reqEmail,
                          expression: "param.reqEmail",
                        },
                      ],
                      ref: "email",
                      attrs: { id: "reqEmail", type: "text" },
                      domProps: { value: _vm.param.reqEmail },
                      on: {
                        blur: function ($event) {
                          return _vm.checkValidationReqEmail()
                        },
                        change: function ($event) {
                          return _vm.checkReqEmail($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.param, "reqEmail", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "mt10" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.CSBL260.009")) + " "),
            ]),
            _c("ul", { staticClass: "bul_list" }, [
              _c("li", [
                _vm._v(" " + _vm._s(_vm.$t("msg.MYIN030T010.034")) + " "),
                _c("ul", [
                  _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.035")))]),
                ]),
              ]),
              _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.036")))]),
              _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.037")))]),
            ]),
            _c("div", { staticClass: "txt mt10" }, [
              _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.038"))),
            ]),
            _c("div", { staticClass: "normal_box" }, [
              _c("ol", [
                _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.039")))]),
                _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.040")))]),
                _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.041")))]),
                _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.042")))]),
                _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.043")))]),
              ]),
              _c("div", { staticClass: "mt5" }, [
                _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.044"))),
              ]),
              _c("div", { staticClass: "mt15" }, [
                _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.045"))),
              ]),
            ]),
          ]),
        ]),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checkAgreement,
                expression: "checkAgreement",
              },
            ],
            attrs: { type: "checkbox", id: "chkAgree" },
            domProps: {
              checked: Array.isArray(_vm.checkAgreement)
                ? _vm._i(_vm.checkAgreement, null) > -1
                : _vm.checkAgreement,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.checkAgreement,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.checkAgreement = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.checkAgreement = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.checkAgreement = $$c
                  }
                },
                function ($event) {
                  return _vm.checkReqAgreement()
                },
              ],
            },
          }),
          _c("label", { attrs: { for: "chkAgree" } }, [
            _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.045"))),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt10" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg ml_auto",
              attrs: { href: "#none" },
              on: { click: _vm.reqMileageToPoint },
            },
            [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.007")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#none" },
              on: {
                click: function ($event) {
                  ;[_vm.$emit("close"), _vm.clearData()]
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.MYIN030T010.022")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }